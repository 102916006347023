var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isTranscriptionReadOnly)?_c('div',{staticClass:"edition-action-bar m-b-md"},[_c('div',{staticClass:"field is-grouped"},[_c('p',{staticClass:"control"},[_c('save-transcription-button')],1),(
        _vm.currentSection === 'transcription' &&
        _vm.currentUserIsTeacher &&
        _vm.selectedUserId == _vm.document.user_id
      )?_c('p',{staticClass:"control"},[_c('validate-transcription-button',{attrs:{"doc-id":_vm.document.id}})],1):_vm._e(),(
        _vm.currentSection === 'speech-parts' &&
        _vm.currentUserIsTeacher &&
        _vm.selectedUserId == _vm.document.user_id
      )?_c('p',{staticClass:"control m-b-md"},[_c('validate-speech-parts-button',{attrs:{"doc-id":_vm.document.id}})],1):_vm._e(),(
        _vm.currentSection === 'transcription' &&
        _vm.currentUserIsTeacher &&
        _vm.currentUser.id !== _vm.selectedUserId
      )?_c('p',{staticClass:"control"},[_c('clone-transcription-button')],1):_vm._e(),(
        _vm.currentSection === 'transcription' &&
        (_vm.currentUserIsTeacher || !_vm.isTranscriptionReadOnly)
      )?_c('p',{staticClass:"control"},[_c('delete-transcription-button',{attrs:{"doc-id":_vm.document.id,"user-id":_vm.selectedUserId}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }